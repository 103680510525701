import { observable, action, computed, toJS } from 'mobx';

import { apiRoutes, userRoles, api, userGroups } from '..';

export default class UserStore {
  @observable info = {};

  @computed get isFetched() {
    return Object.keys(this.info).length !== 0
      && this.info.constructor === Object;
  }

  @computed get fullName() {
    return `${this.info.firstName} ${this.info.lastName}`;
  }

  @computed get isCommonUser() {
    return this.info.role.label === userRoles.CONTACT;
  }

  /* USER GROUP */

  @computed get isTessile() {
    return this.isRefTessile || this.isManagerTessile;
  }

  @computed get isRefTessile() {
    return this.info.groups.filter(g => g.label === userGroups.REF_TESSILE).length;
  }

  @computed get isManagerTessile() {
    return this.info.groups.filter(g => g.label === userGroups.MANAGER_TESSILE).length;
  }

  @computed get isPuericultura() {
    return this.isRefPuericultura || this.isManagerPuericultura;
  }

  @computed get isRefPuericultura() {
    return this.info.groups.filter(g => g.label === userGroups.REF_PUERICULTURA).length;
  }
  @computed get isManagerPuericultura() {
    return this.info.groups.filter(g => g.label === userGroups.MANAGER_PUERICULTURA).length;
  }

  @action setUserInfo(info) {
    this.info = info;
  }

  /* COOKIE CONSENT ----------------------------------------------------- */

  @computed get consentGiven() {
    return this.info.consentGiven;
  }

  setConsent = () => {
    api.put(apiRoutes.USER_CONSENT);
  }

  /* NOTIFICATIONS ------------------------------------------------------ */
  @observable notifications = [];

  @computed get hasNotifications() {
    return this.notifications
      .filter(n => n.source === 'Material')
      .length > 0;
  }

  @computed get unreadNotifications() {
    return this.notifications.filter(n => !n.read).length;
  }

  @action fetchUserNotifications() {
    api
      .get(apiRoutes.USER_NOTIFICATIONS)
      .then((response) => {
        this.notifications = response.data ? response.data.data : {};
      });
  }

  @action markNotificationAsRead() {
    api
      .post(apiRoutes.USER_NOTIFICATIONS_MARKASREAD, {
        action: 3,
        data: this.notifications.map(n => n.id),
      })
      .then((response) => {
        this.notifications = response.data ? response.data.data : {};
      });
  }
}
