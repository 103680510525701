import { AuthStore, DeviceStore, TranslationStore, SettingStore } from '@carosello/ui-commons';

import { api, apiRoutes, system, defaultLanguage } from '..';
import ErrorStore from './ErrorStore';
import NavigationStore from './NavigationStore';
import PackagingStore from './PackagingStore';
import PathStore from './PathStore';
import UserStore from './UserStore';

export const authStore = new AuthStore(api);
export const deviceStore = new DeviceStore();
export const errorStore = new ErrorStore();
export const navigationStore = new NavigationStore();
export const packagingStore = new PackagingStore();
export const pathStore = new PathStore();
export const translationStore = new TranslationStore(
  api,
  apiRoutes.LANGUAGES,
  apiRoutes.TRANSLATIONS,
  apiRoutes.COUNTRY,
  system.PREFIX,
  {
    iso3: defaultLanguage.defaultLang,
    description: defaultLanguage.defaultLangDescr,
    flagCss: defaultLanguage.defaultFlag,
  },
);
export const userStore = new UserStore();
export const settingStore = new SettingStore();

