/* eslint-disable */

import axios from 'axios';
import { errorStore } from '.';

const api = axios.create({
  baseURL: `${process.env.PUBLIC_URL}/admin/api/portal`,
  // baseURL: 'https://localhost:44337/api/portal',
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  // config.headers['X-User-Lang-Code'] = 'EN';
  config.headers[window.appConfig.antiforgery.headerName] = localStorage.getItem('antiforgeryToken');
  return config;
}, error => Promise.reject(error));


api.interceptors.response.use(response => response, (error) => {
  if (error.response && error.response.status === 401) {
    window.location = '/admin/login';
    return Promise.reject(error);
  } else if (axios.isCancel(error)) {
    return;
  }
  errorStore.addError(error);
  return Promise.reject(error);
});

function get() {
  var call;
  return function (url) {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    return api.get(url, { cancelToken: call.token });
  }
}

const sequentialGet = get();

export { sequentialGet, api };
