import moment from 'moment/min/moment-with-locales';

import { routes, labels, fileTypes } from '.';

moment.locale('it');

export function dayMonthFullYear(date, _ = '/') {
  // dd/mm/yyyy
  return date ? moment(date).format(`DD${_}MM${_}YYYY`) : date;
}

/* PATH ------------------------------------------ */

export function getRoute(route, level) {
  const split = route.split('/');
  let res = '';
  for (let i = 1; i <= level; i += 1) {
    if (split[i]) {
      res += `/${split[i]}`;
    }
  }
  return res;
}

export function isRoute(currentRoute, route) {
  return currentRoute.startsWith(route.substr(0, route.indexOf(':')));
}

export function getTitleFromRoute(route) {
  switch (route) {
    case routes.ROOT:
    case routes.HOMEPAGE:
      return labels.HOMEPAGE;
    case routes.PUERICULTURA:
      return labels.PUERICULTURA;
    case routes.TESSILE:
      return labels.TESSILE;
    case routes.TEST:
      return labels.TEST;

    default:
      return '';
  }
}

/* SEARCH ------------------------------------------ */

export function getRouteFromTitle(name, detail = false) {
  switch (name.toLowerCase()) {
    case labels.TESSILE:
      return detail ? routes.TESSILE_CARTELLA_DETTAGLIO : routes.TESSILE;
    case labels.PUERICULTURA:
      return detail ? routes.PUERICULTURA_CARTELLA_DETTAGLIO : routes.PUERICULTURA;
    case labels.TEST:
      return detail ? routes.TEST_CARTELLA_DETTAGLIO : routes.TEST;
    default:
      return '';
  }
}

export function buildRoute(item) {
  let route;
  const { pathSegments, name, id, type } = item;
  const isDirectory = type !== fileTypes.DIRECTORY;

  if (!pathSegments.length) {
    return getRouteFromTitle(name);
  }

  pathSegments.forEach((p, i) => {
    if (i === 0) {
      route = getRouteFromTitle(p.directoryName);
    }
    if ((i === pathSegments.length - 1)
      && isDirectory) {
      route += `/${p.directoryId}`;
    }
  });

  route += `/${isDirectory ? 'materiale/' : ''}${id}`;

  return route;
}

export function trimContent(content, size) {
  const match = /<br>/.exec(content);
  return (content.length > size || match ? content.slice(0, (match ? match.index : size)).trim().concat('...') : content);
}

export function buildUrl(route) {
  return `${window.location.origin}/admin/api/portal${route}`;
}
