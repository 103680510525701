import { action, observable, computed } from 'mobx';

import { apiRoutes, api, packagings, navigationStore, fileTypes } from '..';

import imgPuericultura from '../../assets/images/bg_puericultura.jpg';
import imgTessile from '../../assets/images/bg_texile.jpg';

export default class PackagingStore {
  @observable items = [];
  @observable isFetched = false;

  @action fetchPackagings() {
    const query = apiRoutes.PACKAGING
      .replace('{parentId}', '00000000-0000-0000-0000-000000000000');

    api
      .get(query)
      .then((response) => {
        this.items = response ? response.data.data
          .filter(i => i.type === fileTypes.DIRECTORY)
          .map((item) => {
            navigationStore.setData(item.name, {
              parentId: item.id,
              img: this.getImg(item),
            });
            return item;
          }) : [];
        this.isFetched = true;
      });
  }

  /* PACKAGING DETAIL ---------------------------------------- */
  @observable selectedPackaging = {};

  @computed get packagingDetailIsFetched() {
    return Object.keys(this.selectedPackaging).length !== 0
      && this.selectedPackaging.constructor === Object;
  }

  @computed get noMaterials() {
    return this.packagingDetailIsFetched && this.selectedPackaging.data.peek().length === 0;
  }

  @action fetchPackagingDetail(parentId) {
    const query = apiRoutes.PACKAGING
      .replace('{parentId}', parentId);

    return new Promise((resolve) => {
      api
        .get(query)
        .then((response) => {
          this.selectedPackaging = response ? response.data : {};
          resolve(this.selectedPackaging);
        });
    });
  }

  /* MATERIAL DETAIL ---------------------------------------- */
  @observable selectedMaterial = {};
  @observable templateId = '';

  @computed get materialDetailIsFetched() {
    return Object.keys(this.selectedMaterial).length !== 0
      && this.selectedMaterial.constructor === Object;
  }

  @action fetchMaterialDetail(parentId, materialId) {
    const query = apiRoutes.PACKAGING_MATERIAL_DETAIL
      .replace('{parentId}', parentId)
      .replace('{materialId}', materialId);

    return new Promise((resolve) => {
      api
        .get(query)
        .then((response) => {
          this.selectedMaterial = response ? response.data : {};
          this.templateId = this.selectedMaterial.mainTemplateId;
          resolve(this.selectedMaterial);
        });
    });
  }

  @computed get hasAttachments() {
    return this.materialDetailIsFetched && this.selectedMaterial.attachments.length > 0;
  }

  @action setTemplateId(templateId) {
    this.templateId = templateId;
  }

  /* HELPERS ---------------------------------------- */
  getImg = (packaging) => {
    switch (packaging.name) {
      case packagings.PUERICULTURA:
        return imgPuericultura;
      case packagings.TESSILE:
        return imgTessile;
      case packagings.TEST:
        return imgTessile;
      default:
        return '';
    }
  }

  @action cleanUp() {
    this.items = [];
    this.isFetched = false;
    this.cleanUpDetail();
  }

  @action cleanUpDetail() {
    this.selectedPackaging = {};
    this.selectedMaterial = {};
  }
}
