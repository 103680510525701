export const routes = {
  BACK_OFFICE: '/admin/',
  HOMEPAGE: '/homepage',
  ROOT: '/',

  PUERICULTURA: '/puericultura',
  PUERICULTURA_CARTELLA: '/puericultura/:directoryId',
  PUERICULTURA_CARTELLA_DETTAGLIO: '/puericultura/:directoryId/materiale/:materialId',
  TESSILE: '/textile',
  TESSILE_CARTELLA: '/textile/:directoryId',
  TESSILE_CARTELLA_DETTAGLIO: '/textile/:directoryId/materiale/:materialId',

  CARTELLA: ':directoryName/:directoryId',
  CARTELLA_DETTAGLIO: ':directoryName/:directoryId/materiale/:materialId',

  NOTIFICHE: '/_notifications',
  TEST: '/test',
  TEST_CARTELLA: '/test/:directoryId',
  TEST_CARTELLA_DETTAGLIO: '/test/:directoryId/materiale/:materialId',
};

export const apiRoutes = {
  COUNTRY: '/countries/items',
  FILE_ZIP: '/files/batch',
  FILE_PREVIEW: '/files/items/{fileId}/preview',
  LANGUAGES: '/languages/items',
  PDF: '/admin/pdf-viewer/web/viewer.html?file=/admin/api/portal/files/items/{fileId}#zoom=page-fit',
  TRANSLATIONS: '/translations/map',
  USER: '/users/current',
  USER_CONSENT: '/users/current/consent',
  USER_NOTIFICATIONS: '/users/current/notifications/items',
  USER_NOTIFICATIONS_MARKASREAD: '/users/current/notifications/batch',

  SEARCH: '/azure/suggest/',
  PACKAGING: '/materials/items/{parentId}?sortBy=code|asc,name|asc',
  PACKAGING_MATERIAL_DETAIL: '/materials/items/{parentId}/files/{materialId}',
};

export const labels = {
  CODICE_EAN: 'codice_ean',
  CERCA: 'cerca',
  CERCA_MATERIALI: 'cerca_materiali',
  CHIUDI: 'chiudi',
  COOKIES_DISCLAIMER: 'cookies_disclaimer',
  COOKIES_MORE_INFO: 'cookies_more_info',
  CREATO_DA: 'creato_da',
  DATA_CREAZIONE: 'data_creazione',
  DATA_MODIFICA: 'data_modifica',
  GOTO_BACK_OFFICE: 'goto_back_office',
  HOMEPAGE: 'homepage',
  LOGOUT: 'logout',
  MODIFICATO_DA: 'modificato_da',
  NO_DATA: 'no_data',
  NO_NOTIFICHE: 'no_notifiche',
  PUERICULTURA: 'puericultura',
  SCARICA: 'scarica',
  TESSILE: 'textile',
  TEST: 'test',
};

export const errors = {
  ERR_400: 'err_400',
  ERR_404: 'err_404',
  ERR_500: 'err_500',
};

/* BACK END CONSTANTS */

export const defaultLanguage = {
  defaultLang: 'ita',
  defaultLangDescr: 'Italiano',
  defaultFlag: 'flag-icon flag-icon-it',
};

export const fileTypes = {
  DIRECTORY: 'Directory',
  DOC: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  FILE: 'File',
  JPEG: 'image/jpeg',
  PDF: 'application/pdf',
  PNG: 'image/png',
  PPT: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  SVG: 'image/svg+xml',
  ZIP: 'application/x-zip-compressed',
};

export const userRoles = {
  CONTACT: 'role_contact',
  ALTRI: '',
};

export const userGroups = {
  REF_TESSILE: 'group_referenti_tessile',
  MANAGER_TESSILE: 'group_manager_tessile',
  REF_PUERICULTURA: 'group_referenti_puericultura',
  MANAGER_PUERICULTURA: 'group_manager_puericultura',
};

/* PACKAGING */

export const packagings = {
  PUERICULTURA: 'Puericultura',
  TESSILE: 'Textile',
  TEST: 'Test',
};

/* OTHER CONSTANTS */

export const colors = {
  DANGER: '#EB5757',
  MAIN: '#707295',
  MAIN_DARK_40: '#44455B',
  MAIN_LIGHT_20: '#ABACC0',
  MAIN_LIGHT_40: '#E2E3E9',
  PRIMARY: '#009fe3',
  PRIMARY_SAT_40: '#d14a7d',
  PRIMARY_LIGHT_40: '#f383ad',
  SECONDARY: '#779651',
  SECONDARY_LIGHT_40: '#adc492',
  SUCCESS: '#6FCF97',
  WARNING: '#FFD041',
  LIGHT_BG: '#F8F9FD',
};

const translationPrefix = (process.env.REACT_APP_TRANSLATION_PREFIX || '').toString();

export const system = {
  PREFIX: translationPrefix === '0' ? '' : translationPrefix,
};
