import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import { Layout } from './pages';
import { routes, history } from './commons';
import Redirect from './pages/Redirect';

export default class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path={routes.NOTIFICHE} component={Redirect} />
          <Route path={routes.ROOT} component={Layout} />
          <Route path={routes.BACK_OFFICE} />
        </Switch>
      </Router>
    );
  }
}
