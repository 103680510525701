import { observable, action, computed } from 'mobx';

import { routes, labels, getRoute, userStore } from '..';

import iconTessile from '../../assets/icons/texile_icon_1.svg';
import iconPuericultura from '../../assets/icons/baby_icon_1.svg';

export default class NavigationStore {
  @observable pages = [
    {
      route: routes.TESSILE,
      label: labels.TESSILE,
      path: './Packaging',
      icon: iconTessile,
      hidden: !userStore.isTessile,
    },
    {
      route: routes.PUERICULTURA,
      label: labels.PUERICULTURA,
      path: './Packaging',
      icon: iconPuericultura,
      hidden: !userStore.isPuericultura,
    },
    /* NOT IN MENU ------------------------------------------ */
    {
      route: routes.HOMEPAGE,
      label: labels.HOMEPAGE,
      path: './Homepage',
    },
    /* TESSILE */
    {
      route: routes.TESSILE_CARTELLA,
      label: labels.TESSILE,
      path: './Packaging',
      hidden: !userStore.isTessile,
    },
    {
      route: routes.TESSILE_CARTELLA_DETTAGLIO,
      label: labels.TESSILE,
      path: './MaterialDetail',
      hidden: !userStore.isTessile,
    },
    /* PUERICULTURA */
    {
      route: routes.PUERICULTURA_CARTELLA,
      label: labels.PUERICULTURA,
      path: './Packaging',
      hidden: !userStore.isPuericultura,
    },
    {
      route: routes.PUERICULTURA_CARTELLA_DETTAGLIO,
      label: labels.PUERICULTURA,
      path: './MaterialDetail',
      hidden: !userStore.isPuericultura,
    },
    /* TEST */
    {
      route: routes.TEST,
      label: labels.TEST,
      path: './Packaging',
    },
    {
      route: routes.TEST_CARTELLA,
      label: labels.TEST,
      path: './Packaging',
    },
    {
      route: routes.TEST_CARTELLA_DETTAGLIO,
      label: labels.TEST,
      path: './MaterialDetail',
    },
  ];

  @computed get menu() {
    return this.pages.filter(p => p.icon);
  }

  checkElem = (elem, path) => (path === elem.route
    || (path === routes.ROOT && elem.route === routes.HOMEPAGE)
    || getRoute(path, 1) === elem.route
    || getRoute(path, 2) === elem.route);

  @action setActive(path) {
    this.pages = this.pages.map((elem) => {
      elem.active = this.checkElem(elem, path);
      if (elem.children) {
        elem.children.map((child) => {
          child.active = this.checkElem(child, path);
          elem.open = child.active ? true : elem.open;
          elem.active = child.active ? true : elem.active;
          return child;
        });
      }
      return elem;
    });
  }

  @action toggleActive(index, childIndex = null) {
    this.pages = this.pages.map((elem, i) => {
      elem.active = (i === index);
      if (elem.children) {
        elem.children.map((child, j) => {
          child.active = (j === childIndex);
          elem.open = child.active ? true : elem.open;
          elem.active = child.active ? true : elem.active;
          return child;
        });
      }
      return elem;
    });
    if (this.isToggled) {
      this.closeSubMenus();
    }
  }

  @action toggleOpen(index) {
    this.pages = this.pages.map((elem, i) => {
      if (i === index) {
        elem.open = !elem.open;
      }
      return elem;
    });
  }

  @action closeSubMenus() {
    this.pages = this.pages.map((elem) => {
      elem.open = false;
      return elem;
    });
  }

  /* DATA */
  @action setData(label, data) {
    this.pages = this.pages.map((p) => {
      if (p.label === label.toLowerCase()) {
        p.data = { ...data };
      }
      return p;
    });
  }

  /* DOCUMENT TITLE */
  @observable documentTitle = '';

  @action setDocumentTitle(documentTitle) {
    this.documentTitle = documentTitle;
  }

  @action cleanDocumentTitle() {
    this.documentTitle = '';
  }
}
