import React, { Component } from 'react';
import { observer } from 'mobx-react';
import queryString from 'query-string';

import { packagingStore, getRouteFromTitle, history, routes } from '../commons';
import Loading from '../components/elements/Loading';

@observer
export default class Redirect extends Component {
  UNSAFE_componentWillMount() {
    const { location: { search } } = this.props;
    const qs = queryString.parse(search);
    const { source, parentItemId, itemId } = qs;
    switch (+source) {
      // material
      case 5:
        packagingStore
          .fetchMaterialDetail(parentItemId, itemId)
          .then((material) => {
            const route = getRouteFromTitle(material.pathSegments[0].directoryName, true)
              .replace(':directoryId', parentItemId)
              .replace(':materialId', itemId);
            history.push(route);
          });
        break;
      default:
        history.push(routes.HOMEPAGE);
        break;
    }
  }

  render() {
    return <Loading />;
  }
}
